<template>
	<contract-notification />
</template>
<script>
import ContractNotification from '@/components/contract/notification/ContractNotification.vue';
export default {
	name: 'ContractNotificationView',
	components: { ContractNotification },
};
</script>
