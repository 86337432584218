import { mapActions, mapState } from 'vuex';
import { showError } from '@/helpers/globalHelpers';
import ContractReportDialog from '@/components/contract/elements/RegisterContractReportDialog/index.vue'
export default {
  name: 'DownloadReportContract',

  components: {
    ContractReportDialog
  },

  data() {
    return {
      loading: false,
      downloadLoading: false,
      registerReportDialog: false,
      item: {},
      filters: {
        page: 1,
        pagination: true,
        per_page: process.env.VUE_APP_PAGINATION_PER_PAGE || 10,
      },
      headersContractReport: [
        { text: 'Responsable', value: 'responsible.fullName', sortable: false, class: 'primary--text' },
        { text: 'Comentario' , value:'comment', sortbale:false,class:'primary--text'},
        { text: 'Estado', value: 'status', sortable: false, class: 'primary--text' },
        { text: 'Adenda', value: 'flag_addendum', sortable: false, class: 'primary--text' },
        { text: 'Carta', value: 'flag_letter', sortable: false, class: 'primary--text' },
        { text: 'Acta', value: 'flag_report', sortable: false, class: 'primary--text' },
        { text: 'Anexo', value: 'flag_annex', sortable: false, class: 'primary--text' },
        { text: 'Archivo', value: 'file', sortable: false, class: 'primary--text' },
      ]
    };
  },

  computed: {
    breadcrumbs: function () {
      return [
        {
          text: 'Inicio',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: 'Dashboard',
          },
        },
        {
          text: 'Legal',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: 'ContractHome',
          },
        },
        {
          text: 'Reporte',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: 'ContractReport',
          },
        },
        {
          text: 'Descarga de contratos',
          disabled: true,
          href: '/',
        },
      ];
    },
    ...mapState('contract', ['contractsReport','contractsReportPagination'])
  },

  methods: {
    ...mapActions('contract', ['listAllContractReport', 'getContractReportFileSigned']),

    async getContractsReport() {
      this.loading = true;
      const { error } = await this.listAllContractReport(this.filters);
      if (error) showError(error);
      this.loading = false;
    },

    registerReportDialogView(item) {
      this.item = Object.assign({}, item);
      this.registerReportDialog = true;
      this.$nextTick(() => {
        document.activeElement.blur();
      });
    },

    async downloadFile(contractReportId) {
      this.downloadLoading = true;
      try {
        const { error, response } = await this.getContractReportFileSigned(contractReportId);
        if (error) {
          showError(error);
        } else if (response && response.message) {
          const { message } = response;
          this.$swal.fire({
            title: "Descarga iniciada",
            text: message,
            icon: "success",
            confirmButtonColor: "#0039a6",
            confirmButtonText: "Ok",
          });          
          // Crear un enlace temporal y programáticamente hacer clic para iniciar la descarga
          // const link = document.createElement('a');
          // link.href = response.signedUrl;
          // link.setAttribute('download', ''); // Puedes especificar un nombre de archivo aquí si es necesario
          // document.body.appendChild(link);
          // link.click();
          // link.remove();
        }
      } catch (err) {
        showError("Error al descargar el archivo: " + err.message);
      } finally {
        this.downloadLoading = false;
      }
    }
  },

  created() {
    this.getContractsReport();
  },

  beforeMount() {

  },

  mounted() {

  },

  beforeUpdate() {

  },

  updated() {

  },

  beforeDestroy() {

  },

  destroyed() {

  }
};