<template>
	<download-contract />
</template>
<script>
import DownloadContract from '@/components/contract/downloadContract/DownloadContract.vue';

export default {
	name: 'DownloadContractView',
	components: { DownloadContract },
};
</script>
