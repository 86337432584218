import { mapActions, mapState } from 'vuex';
import { $EventBus } from '@/main';
import moment from 'moment';
import { getStatus, stateList } from '@/helpers/statusAgreement.js';

export default {
	name: 'ContractNotification',
	props: {},
	data: () => ({
		isLoading: false,
		dateRange: null,
		statusList: stateList,
		filters: {
			q: null,
			page: 1,
			date_to: null,
			fate_from: null,
			status: [
				'LEGAL_REVISION',
				'LEGAL_APROBACION',
				'LEGAL_APROBACION_PREAPROBADO',
			],
			pagination: true,
			per_page: process.env.VUE_APP_PAGINATION_PER_PAGE || 10,
		},
		headers: [
			{
				text: 'Contrato:',
				value: 'agreement.name',
				sortable: false,
				align: 'left',
				class: 'primary--text',
			},
			{
				text: 'Creador:',
				value: 'userSend.fullName',
				sortable: false,
				align: 'left',
				class: 'primary--text',
			},
			{
				text: 'Cliente/Proveedor:',
				value: 'agreement.owner.name',
				sortable: false,
				align: 'left',
				class: 'primary--text',
			},
			{
				text: 'Fecha y hora:',
				value: 'created_at',
				sortable: false,
				align: 'left',
				class: 'primary--text',
			},
			{
				text: 'Estado de contrato:',
				value: 'status',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: '',
				value: 'actions',
				sortable: false,
				align: 'right',
				class: 'primary--text',
			},
		],
	}),
	computed: {
		...mapState('agreement', [
			'agreementNotifications',
			'agreementNotificationsPagination',
		]),
		breadcrumbs: function () {
			return [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: 'Legal',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'ContractHome',
					},
				},
				{
					text: 'Notificaciones',
					disabled: true,
					href: '/',
				},
			];
		},
	},
	watch: {},
	created() {
		this.listenFilters();
	},
	methods: {
		...mapActions('agreement', ['listAgreementNotification', 'cleanNotifications']),
		...mapActions('agreementNotification', ['saveNotificationRead']),

		getStatus,

		async sendNotificationRead(item) {
			if (item.id) {
				const data = {
					id: item.id,
					origin: 'legal',
				};
				const { ok, error } = await this.saveNotificationRead(data);
				if (!ok) {
					this.showSnack(error, 'error');
				}
			}
		},

		async getAgreemenNotification(filters) {
			this.isLoading = true;
			const { ok, error } = await this.listAgreementNotification(filters);
			if (!ok) {
				this.showSnack(error, 'error');
			}
			this.isLoading = false;
		},

		showSnack(error, color = 'success') {
			let msmError = error?.data?.message;
			msmError = Array.isArray(msmError) ? msmError.join('<br> ') : msmError;
			$EventBus.$emit('showSnack', color, msmError);
		},

		formatDate(date) {
			if (!isNaN(new Date(date))) {
				moment.locale('es');
				return date ? moment(date).format('LLL') : null;
			} else {
				return null;
			}
		},

		openNotification(item) {
			this.sendNotificationRead(item);
			this.$router.push({
				name: 'ContractReadAgreement',
				params: {
					agreementId: item?.agreement_id,
				},
			});
		},

		listenFilters() {
			let filters = { ...this.filters };
			filters.status = filters.status.join(',') || null;
			this.getAgreemenNotification(filters);
		},

		listenDatePicker(val) {
			moment.locale('es');
			this.filters.date_from = val[0] ? moment(val[0]).format('YYYY-MM-DD') : null;
			this.filters.date_to = val[1] ? moment().format('YYYY-MM-DD') : null;
			this.listenFilters();
		},
	},
	beforeDestroy() {
		this.cleanNotifications();
	},
	components: {},
};
