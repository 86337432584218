import { mapActions } from 'vuex';
import { showSnackBar } from "@/helpers/globalHelpers";

export default {
	name: 'ContractReportDialog',
	data() {
		return {
			loadingSave: false,
			valid: false,
			listError: [],
			contractReportForm: {
				comment: null,
				flag_addendum: false,
				flag_letter: false,
				flag_report: false,
				flag_annex: false,
			},
		};
	},
	props: {
		value: Boolean,
	},
	computed: {
		dialog: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	methods: {
		...mapActions('contract', ['registerContractReport']),
		// close() {
		// 	this.dialog = false;
		// 	this.listError = [];
		// },
		async saveRegisterReportContract() {
			this.loadingSave = true;
			const { error, ok } = await this.registerContractReport(this.contractReportForm);

			if (ok) {
				this.$swal.fire({
					title: "¡El reporte de contrato fue creado con éxito!",
					text: "El reporte de contrato se está generando, regresa en breve para poder descargarlo.",
					icon: "success",
					confirmButtonColor: "#0039a6",
					confirmButtonText: "Ok",
				});

				this.contractReportForm = {
					comment: null,
					flag_addendum: false,
					flag_letter: false,
					flag_report: false,
					flag_annex: false,
				};

				await this.$emit('getContractsReport');
			} else {
				let errorMsm = error?.data?.message;
				errorMsm = Array.isArray(errorMsm) ? errorMsm.join(" <br> ") : errorMsm;
				showSnackBar(errorMsm, "error");
			}

			this.loadingSave = false;
			this.dialog = false;

		},

		close() {
			this.dialog = false;
			this.contractReportForm = {
				comment: null,
				flag_addendum: false,
				flag_letter: false,
				flag_report: false,
				flag_annex: false,
			};
			this.$refs.form.resetValidation();
		}
	},
};
